
const splitTitle = (title) => {
    const titles = {
      breadcrumb: "",
      title: "",
    };

    const t = title
      .replace("<br/>", "<br>")
      .replace("<br />", "<br>")
      .split("<br>");
    
    if (t.length > 1) {
      titles.breadcrumb = t[0];
      titles.title = t[1];
    } else {
      titles.breadcrumb = t[0];
      titles.title = t[0];
    }
    return titles;
  };

  export {splitTitle}
